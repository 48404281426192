import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  description?: string
  title?: string
}

export const Paragraph = memo(function Paragraph({
  description,
  title,
}: Props) {
  if (!description || !title) {
    return null
  }

  return (
    <Container>
      <Wrapper>
        {title ? <Title>{title}</Title> : null}
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  padding: 11.25vw 12.5vw;

  @media (max-width: 1439px) {
    padding: 11.25vw 1.5625rem;
  }

  @media (max-width: 1199px) {
    padding: 7.5rem 1.5625rem 5rem;
  }
`

const Wrapper = styled.div`
  max-width: 120rem;
  margin: 0 auto;
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: normal;
  font-size: 5.31vw;
  line-height: 1.058;
  text-align: center;

  @media (max-width: 1199px) {
    font-size: 3.4375rem;
  }
`

const Description = styled.div`
  margin: 5vw auto 0;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: normal;
  font-size: 1.06vw;
  line-height: 1.64;

  a {
    text-decoration: underline;
  }

  ul {
    padding-inline-start: 0.9375rem;
    list-style-image: url('/arrow_list-style-image.png');

    li {
      margin: 0.7vw 0;
      padding-left: 0.7vw;
      font-size: 0.93vw;
    }
  }

  @media (max-width: 1199px) {
    margin-top: 3.75rem;
    font-size: 0.8125rem;

    ul {
      li {
        margin: 0.625rem 0;
        padding-left: 0.625rem;
        font-size: 0.75rem;
      }
    }
  }
`
